import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import SearchResults from "../components/SearchResults";
import "./Search.css";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      searchValue: "",
      searchSubmitValue: "",
      data: {},
      loading: false,
      errormsg: ""
      //searchResults: [],
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("q")) {
      const query = params.get("q");
      this.setState({searchValue: query});
      this.fetchData(query);
    }
  }
  fetchData(searchValue) {
    if (searchValue.length > 0) {
      this.setState({loading: true});
      const url = process.env.REACT_APP_API_URL;
      fetch(url+encodeURI("?query="+searchValue))
        .then(response => {
          if (response.ok) return response.json();
          if (response.status === "400") throw new Error(response.error);
          throw new Error('Tuntematon virhe haettaessa tietoja!');
        })
        .then(data => {
          this.setState({ data: data, loading: false, errormsg: "", searchSubmitValue: searchValue });
        })
        .catch(error => this.setState({ errormsg: error, loading: false }));
    }
  }

  handleSearchValueChange(event) {
    this.setState({searchValue: event.target.value});
  }

  handleSearchSubmit(event) {
    const value = event.target[0].value;
    event.preventDefault();
    // Lose focus of text input to hide mobile keyboard
    event.target[0].blur();
    document.title = value === '' ? 'Viittomahaku' : `Viittomahaku: ${value}`;
    this.props.history.push({
      pathname: '/',
      search: '?q=' + value
    });
    this.fetchData(value);
  }

  render() {
    return (
      <div className="Search">
        <SearchBar value={this.state.searchValue} handleSubmit={this.handleSearchSubmit.bind(this)} searchValueChangeHandler={this.handleSearchValueChange.bind(this)} className="searchBar" />
        <SearchResults data={this.state.data} loading={this.state.loading} searchVal={this.state.searchValue} />
      </div>
    );
  }
}
export default withRouter(Search);