import React, { Component } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import ModalContainer from "../containers/ModalContainer"
import "./SearchResults.css"
const Highlight = require("react-highlighter");

const headerStyle = {
  fontWeight: "normal",
  textAlign: "left",
  marginBottom: "10px"
};
const sectionStyle = {
  textAlign: "left"
};
const listStyle = {
  listStyleType: "none",
  marginTop: "0",
  paddingLeft: "15px"
}
const listItemStyle = {
  marginBottom: "1.2em",
  display: "block"
}
const loaderStyle = {
  margin: "40px auto 40px auto",
  borderColor: "red"
}
const errorStyle = {
  color: "red"
}

function setModalContainer({ url, kwds, video, videoIframeId }) {
  if (url.indexOf("signbank.csc.fi") !== -1 && video && video.src) {
    // Show video modals for Signbank
    return <ModalContainer title={kwds} video={video || {}} source={"Suomen Signbank"} />
  }
  else if (url.indexOf("suvi.viittomat.net") !== -1 && videoIframeId) {
    // Show video modals for Suvi
    return <ModalContainer title={kwds} videoIframeId={videoIframeId} source={"Suvi-verkkosanakirja"} />
  }

  return null
}

function ListItem(props) {
  const { url, kwds } = props;
  return (
    <li style={listItemStyle} className="list-item">
      <a href={url} className="inline-link" target="_blank" rel="noopener noreferrer"><Highlight search={props.searchVal}>{kwds}</Highlight></a>
      {
        setModalContainer(props)
      }
      <span className="source-text"><strong>{props.source}</strong></span>
    </li>
  );
}

function DisplayResults(props) {
  const errormsg = props.errormsg;
  if (errormsg && errormsg.length > 1) {
    return (<h2 v-if="errormsg" style={errorStyle}>{errormsg}</h2>);
  } else {
    return (
      <div className="source-results">
        <h2 className="results-list" style={headerStyle}>Hakutulokset</h2>
        <ul style={listStyle}>
          {props.data && props.data["message"] && props.data["message"].length > 0
            ? props.data["message"].map((result, index) => {
              return (<ListItem key={index} url={result.url} kwds={result.kwds} searchVal={props.searchVal} video={result.video} videoIframeId={result.videoIframeId} source={result.source} />);
            })
            : <p>Ei hakutuloksia.</p>
          }
        </ul>
      </div>
    );
  }
}

export default class SearchResults extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="SearchResults">
        <section style={sectionStyle}>
          {this.props.loading ?
            <div style={loaderStyle}>
              <PulseLoader
                sizeUnit={"px"}
                size={50}
                color={"#a9cde5"}
                loading={this.props.loading}
              />
            </div>
            : null
          }
          {
            !this.props.loading && this.props.data && this.props.data.message && <DisplayResults data={this.props.data} errormsg={this.state.errormsg} searchVal={this.props.searchVal} />
          }

        </section>
      </div>
    );
  }
}
