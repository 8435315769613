import React, { Component } from "react";
import Routes from "../Routes";
import "./Main.css";

export default class Main extends Component {
  render() {
    return (
      <div className="Main">
        <main>
          <Routes />
        </main>
      </div>
    );
  }
}
