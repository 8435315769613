import React, { Component } from "react";

export default class SearchBar extends Component {
  render() {
    return (
      <div className="SearchBar" onClick={() => this.searchInput.focus()}>
        <form action="" method="GET" onSubmit={this.props.handleSubmit}>
          <div className="formInputs">
          <input value={this.props.value} required={true}  type="text" placeholder="Hae viittomaa" autoFocus={true} className="inputText" onChange={this.props.searchValueChangeHandler} ref={(x) => this.searchInput = x} />
          <input type="submit" value="Hae" className="submitSearch" />
          </div>
        </form>
      </div>
    );
  }
}
