import React, { Component } from "react";
import "./Video.css";

export default class Video extends Component {
  render() {
    return (
      <div className="Video">
        <video preload="metadata" controls muted autoPlay>
          <source src={this.props.src || ""} type={this.props.type || ""} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
}
