import React, { Component } from "react";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <footer>
        <section>
            <header>
                <h5>Haetaan seuraavista aineistoista:</h5>
            </header>
            <div className="footerLinks">
              <a href="https://finsl.signwiki.org" target="_blank" rel="noopener noreferrer">SignWiki Suomi</a>
              <a href="https://papunet.net/materiaalia/kuvapankki/hak/" target="_blank" rel="noopener noreferrer">Papunet Viittomakuvat</a>
              <a href="http://suvi.viittomat.net/" target="_blank" rel="noopener noreferrer">Suvi-verkkosanakirja</a>
              <a href="https://signbank.csc.fi" target="_blank" rel="noopener noreferrer">Suomen Signbank</a>
              <a href="https://sanoistakasin.fi" target="_blank" rel="noopener noreferrer">Sanoista Käsin</a>
            </div>
            <div className="contact footerLinks">
              <p>Yhteystiedot: <a href="https://twitter.com/henrinen" target="_blank" rel="noopener noreferrer">Twitter @henrinen</a></p>
            </div>
        </section>
        </footer>
      </div>
    );
  }
}
