import React, { Component } from "react";
import "./Header.css";

export default class Header extends Component {
  render() {
    return (
      <div className="Header">
        <header>
          <h1>Viittomahaku</h1>
        </header>
      </div>
    );
  }
}
