import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import Modal from "react-modal";
import Video from "../components/Video";
import VideoIframe from "../components/VideoIframe";
import "./ModalContainer.css";

const customStyles = {
    content : {
      top                   : "50%",
      left                  : "50%",
      right                 : "auto",
      bottom                : "auto",
      border                : "0",
      marginRight           : "-50%",
      transform             : "translate(-50%, -50%)",
      maxWidth              : "90vh",
      maxHeight             : "90%",
      boxShadow             : "0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)",
      padding               : "0"
    }
  };

  Modal.setAppElement("#root")

  export default class ModalContainer extends Component {

    constructor(props) {
      super(props);
      this.modalRef = React.createRef()
      this.state = {
        modalIsOpen: false,
      };
      this.openModal = this.openModal.bind(this);
      this.afterOpenModal = this.afterOpenModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.title = props.title || "";
      this.source = props.source || "";
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
    
    afterOpenModal() {
      // references are now sync"d and can be accessed.
      this.subtitle.style.color = "000";
    }
  
    closeModal() {
      this.setState({modalIsOpen: false});
    }

    renderVideo({ video = null, videoIframeId = null, modalRef = null }) {
      if (video && video.src && video.type) {
        return <Video src={video.src} type={video.type} />
      }
      else if (videoIframeId) {
        return <VideoIframe videoIframeId={videoIframeId} modalRef={modalRef} />
      }
      return null
    }

    render() {
      return (
      <div className="inline-button">
        <button onClick={this.openModal} className="button-open-modal"><FontAwesomeIcon icon={faPlay} size="lg" /></button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Video Modal"
        >
          <article className="modal-article">
            <header className="modal-header">
              <div className="header-titles">
                <h2 ref={subtitle => this.subtitle = subtitle}>{this.title}</h2>
                <p className="source">{this.source ? this.source : ""}</p>
              </div>
              <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
                <span aria-hidden="true">×</span>
              </button>
            </header>
            <main className="modal-video" ref={this.modalRef}>
              {
                this.renderVideo({ video: this.props.video, videoIframeId: this.props.videoIframeId, modalRef: this.modalRef })
              }
              <button onClick={this.closeModal} className="button-close-modal-video">Sulje</button>
            </main>
          </article>
        </Modal>
      </div>
      );
    }
  }