import React, { Component } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "./VideoIframe.css";

const BASE_URL = process.env.REACT_APP_API_BASEURL

const loaderStyle = {
  margin: "40px auto 40px auto",
  borderColor: "red"
}

/**
 * Make the video width 605, calculate aspect ratio, set new height, adjust width if needed
 * @param {*} oldWidth
 * @param {*} oldHeight
 * @param {*} modalRef reference to React object to use for calculating maxWidth
 */
function resizeVideo(oldWidth=605, oldHeight, modalRef) {
  // use current modals width as maxwidth for video
  const maxWidth = modalRef.current.offsetWidth
  // Use 75% of window height as max height
  const maxHeight = (window.innerHeight / 100) * 75
  const newWidth = 605 > maxWidth ? maxWidth : 605

  if (oldHeight) {
    const aspectRatio = oldWidth / oldHeight
    const newHeightFromWidth = newWidth / aspectRatio
    // Make sure height is not larger than maxHeight calculated from width
    const newHeight = newHeightFromWidth > maxHeight ? maxHeight : newHeightFromWidth
    // Set new maxWidth based on the newHeight
    const newMaxWidth = newHeight * aspectRatio
    const finalWidth = newMaxWidth < newWidth ? newMaxWidth : newWidth
    return [ finalWidth, newHeight ]
  } else {
    const aspectRatio = 1.333333333
    const newHeightFromWidth = newWidth / aspectRatio
    const newHeight = newHeightFromWidth > maxHeight ? maxHeight : newHeightFromWidth
    return [ newWidth, newHeight ]
  }
}

async function getIframeSrc(videoIframeId, modalRef) {
    const response = await fetch(`${BASE_URL}/videoiframe/${videoIframeId}`)
    if (response.status >= 500) {
      throw new Error(`[500] Error fetching video`)
    }
    const data = await response.json()
    const { videoSrc, videoType } = data
    if (videoSrc) {
      return { videoSrc, videoType: videoType || "video/mp4" }
    }
    const html = data.iframe
    if (html) {
      const iframeDoc = new DOMParser().parseFromString(html.replace(/\\/g, ""), "text/html");
      const iframeEl = iframeDoc.getElementsByTagName("IFRAME")[0]
      const iframeSrc = iframeEl && iframeEl.src
      const iframeWidth = iframeEl && iframeEl.width
      const iframeHeight = iframeEl && iframeEl.height
      const [ width, height ] = resizeVideo(iframeWidth, iframeHeight, modalRef)
      return { iframeSrc, width, height }
    }
    return null
}

export default class VideoIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: false,
      iframeSrc: ""
    };
  }
  componentDidMount() {
    const getIframe = async () => {
      this.setState({ loading: true })
      try {
        const { videoSrc, videoType, iframeSrc, width, height } = await getIframeSrc(this.props.videoIframeId, this.props.modalRef)
        this.setState({
          loading: false,
          videoSrc,
          videoType,
          iframeSrc,
          width,
          height
        });
      } catch (err) {
        this.setState({ loading: false })
        console.log(err)
        if (err.message.startsWith("[500]")) {
          this.setState({ error: "Virhe haettaessa videoa." })
        }
      }
    }
    getIframe()

  }
  render() {
    const { videoSrc, videoType, iframeSrc, width, height } = this.state
    if (this.state.error) {
      return (
        <div className="VideoError">
          <p>{this.state.error}</p>
        </div>
      )
    }
    if (this.state.loading) {
      return (
        <div style={loaderStyle}>
          <PulseLoader
            sizeUnit={"px"}
            size={25}
            color={"#a9cde5"}
            loading={this.props.loading}
          />
        </div>
      )
    }
    if (videoSrc) {
      return (
        <div className="VideoEmbed">
          <video controls><source src={videoSrc} type={videoType}></source>Your browser does not support embedded videos.</video>
        </div>
      )
    } else if (iframeSrc) {
      return (
        <div className="VideoIframe">
          <iframe src={iframeSrc} title={iframeSrc} width={width} height={height} responsive="true" muted={true} frameBorder="0" allow="fullscreen" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
        </div>
      )
    } else if (!this.state.loading && !videoSrc && !iframeSrc) {
      return (
        <div className="NoVideo">
          <p>Viittomalle ei saatavilla videoa.</p>
        </div>
      )
    } else {
      return null
    }
  }
}
